.breadcrumb {
  > li {
    font-size: 0.8rem;

    > a {
      color: inherit;
    }
  }

  > .active {
    font-weight: $semi-bold;
  }
}
