.row-flex-xs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &:before,
  &:after {
    display: none;
  }
}

@media (min-width: $screen-sm) {
  .row-flex-sm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:before,
    &:after {
      display: none;
    }
  }
}

@media (min-width: $screen-md) {
  .row-flex-md {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:before,
    &:after {
      display: none;
    }
  }
}

@media (min-width: $screen-lg) {
  .row-flex-lg {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:before,
    &:after {
      display: none;
    }
  }
}


.col-flex-xs {
  display: flex;
  flex-direction: row;
}

@media (min-width: $screen-sm) {
  .col-flex-sm {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: $screen-md) {
  .col-flex-md {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: $screen-lg) {
  .col-flex-lg {
    display: flex;
    flex-direction: row;
  }
}
