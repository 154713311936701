@import "../abstracts/variables";

.table-default {
  min-width: 100%;

  th,
  td {
    padding: 1.5rem;
    border: 1px solid $grey-light-extra;
  }

  thead {
    th {
      text-align: center;
      color: $white;
      background-color: $black;
    }
  }

  > thead > tr > th {
    border-bottom: 0;
  }

  tbody {
    tr:first-of-type td {
      border-top: 0;
    }
  }

  tfoot {
    border: 1px solid $grey-light-extra;

    td {
      border: 0;
    }
  }
}

.table-container {
  overflow-x: auto;
  width: 100%;
}
