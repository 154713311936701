@import "../abstracts/variables";

.product {
  .product-image {
    width: 100%;
  }

  .product--image-wrapper {
    width: 100%;
    height: 27.5rem;
  }

  .product--detail-row,
  .product--availability,
  .product--order-form {
    margin-top: 3rem;
  }

  .product--price-cart-wrapper {
    display: flex;
    justify-content: space-between;
  }
}

// Views

.product_teaser {
  display: flex;
  flex-direction: column;
  width: 20rem;
  height: 30rem;
  padding: 1rem;
  color: $grey-med;
  border: 1px solid $orange;
  background-color: $white;
  justify-content: space-between;

  .product--name {
    font-size: 1.8rem;
  }

  &:hover,
  &:focus {
    color: inherit;

    .product--name {
      color: $orange-med;
    }
  }
}

.product_thumbnail {
  margin-bottom: 7.5rem;
}

.product_detail {
  padding-top: 2rem;
  border-top: 1px solid $grey-med-light;

  .product--actions,
  .product--description {
    margin-top: 2.5rem;
  }

  select {
    width: 100%;
  }

  input {
    margin-top: 1rem;
    border: 1px solid $grey-light-extra;

    &[type="number"] {
      width: 25%;
    }
  }

  table {
    width: 100%;

    td:first-of-type {
      width: 10rem;
      font-weight: $weight-semi-bold;
    }
  }

  .product--image-wrapper {
    height: 32rem;
  }
}
