.mini-callout {
  width: 100%;
  margin: 0 0 $grid-gutter-width/2;
  color: $white;

  &:hover,
  &:focus {
    color: $white;
  }

  .mini-callout--title,
  .mini-callout--subtitle {
    display: block;
    color: inherit;
    line-height: 1.2;
  }

  .mini-callout--title {
    text-transform: uppercase;
  }

  &.mini-callout--green {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0 0;
    background-color: $green-dark;
    justify-content: space-between;

    .mini-callout--title {
      text-align: center;
      font-family: $font-family-oswald;
      font-size: 3.1rem;
    }

    .mini-callout--image {
      width: 100%;
      height: auto;
    }
  }

  &.mini-callout--orange {
    padding: 3rem;
    background-color: $orange;

    .mini-callout--title,
    .mini-callout--subtitle {
      font-family: $font-family-lato;
    }

    .mini-callout--title {
      font-size: 4.1rem;
      font-weight: 900;
      line-height: 1;
    }

    .mini-callout--subtitle {
      text-transform: uppercase;
      font-size: 2.9rem;
      font-weight: 300;
    }
  }

  &.mini-callout--red {
    display: grid;
    padding: 3rem 2rem;
    background-color: $red;
    grid-template-columns: 1fr auto;
    grid-template-rows: min-content auto;
    grid-template-areas: "title img" "subtitle img";

    .mini-callout--title {
      font-family: $font-family-oswald;
      font-size: 3.2rem;
      grid-area: title;
    }

    .mini-callout--subtitle {
      font-family: $font-family-open-sans;
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1.46;
      grid-area: subtitle;
    }

    .mini-callout--image {
      margin: auto;
      grid-area: img;
    }
  }
}
