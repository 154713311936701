@import "../abstracts/variables";

img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.no-padding {
  padding: 0 !important;
}

.semi-bold {
  font-weight: $weight-semi-bold;
}
