h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  text-transform: capitalize;
}

h1,
.h1 {
  margin-top: 0;
  margin-bottom: 2rem;
  line-height: 3.6rem;
}

h2,
.h2 {
  color: $black;
  line-height: 3.6rem;
}

h3,
.h3 {
  color: $green-med;
  line-height: 3.6rem;
}

h4,
.h4 {
  font-weight: $semi-bold;
  line-height: 3.6rem;
}

h5,
.h5,
h6,
.h6 {
  font-weight: $semi-bold;
  line-height: 2.2rem;
}

h5,
.h5 {
  color: $green-dark;
}

h6,
.h6 {
  color: $black;
}
