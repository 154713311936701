@charset "UTF-8";

/*
* Using the 7-1 architecture pattern for sass to help keep the code clean and easy to navigate
* learn more here https://github.com/HugoGiraudel/sass-boilerplate
*/

// Abstracts
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "abstracts/variables"; //Need to make sure this comes first
@import "abstracts/_colors.scss";
@import "abstracts/_functions.scss";
@import "abstracts/_mixins.scss";
@import "abstracts/_variables.scss";

// Vendor
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../../node_modules/font-awesome/scss/font-awesome.scss";


// Base
@import "base/_body.scss";
@import "base/_grid.scss";
@import "base/_headings.scss";
@import "base/_root.scss";

// Components
@import "components/_backtotop.scss";
@import "components/_blocks.scss";
@import "components/_book.scss";
@import "components/_breadcrumbs.scss";
@import "components/_buttons.scss";
@import "components/_carousel.scss";
@import "components/_checkbox.scss";
@import "components/_course-list.scss";
@import "components/_course.scss";
@import "components/_dropdowns.scss";
@import "components/_featured-items.scss";
@import "components/_footer.scss";
@import "components/_forms.scss";
@import "components/_image-wrapper.scss";
@import "components/_inner-banner.scss";
@import "components/_item-card.scss";
@import "components/_label.scss";
@import "components/_mini-callout.scss";
@import "components/_navbar.scss";
@import "components/_our-story.scss";
@import "components/_panels.scss";
@import "components/_pointer.scss";
@import "components/_product-sidebar-menu.scss";
@import "components/_product.scss";
@import "components/_shopping-cart.scss";
@import "components/_status.scss";
@import "components/_table.scss";
@import "components/_textbook-search.scss";

// Layout
@import "layout/_header.scss";

// Pages


// Themes

