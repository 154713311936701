@import "../abstracts/variables";

.course {
  margin-top: 3rem;

  .course--header {
    padding: 0.5rem 4.5rem;
    color: $white;
    background-color: $black;
  }

  .course--title {
    color: inherit;
  }

  .course--instructor {

  }
}
