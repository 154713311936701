.featured-items-products-wrapper {
  background-color: $black;
  background-image: url("../img/white-dots.svg");
  background-repeat: repeat;
  background-size: cover;
}

.featured-items,
.featured-products {
  padding: 2rem 0 6rem;
}

.featured-items {
  background-color: rgba($green, $featured-items-background-opacity);

  .container > .row > [class*="col"] {
    display: flex;
  }
}

.featured-products {
  background-color: rgba($purple, $featured-items-background-opacity);

  .block-title {
    color: $white;
  }
}
