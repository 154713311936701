.course-list-wrapper {
  display: flex;
  margin-top: 6.5rem;
  align-items: center;

  .course-list {
    margin: 0;
    padding-left: 2.5rem;
  }

  li,
  input {
    display: inline-block;
    margin-left: 1rem;
  }

  li {
    position: relative;
    padding-right: $grid-gutter-width + 5px;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    &:before {
      position: absolute;
      top: 50%;
      right: $grid-gutter-width/2;
      display: flex;
      width: 1rem;
      height: 100%;
      content: "X";
      transform: translateY(-50%);
      pointer-events: none;
      color: $black;
      font-size: 1rem;
      justify-content: center;
      align-items: center;
    }
  }
}
