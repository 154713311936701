/**
 * Remove units from a number.
 *
 * @example
 * // returns 15
 * strip-units(15px);
 *
 * @params {} $number - number with attached units.
 *
 * @returns {number}
 */
@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

/**
 * Convert pixels to rems, assumes 1rem = 10px.
 *
 * @example
 * // returns 1.2rem
 * px-to-rem(12px);
 *
 * @params {number} $number - Pixel value to convert to rems.
 *
 * @returns {rem} - Rem value of number.
 */
@function px-to-rem($number) {
  @return strip-units($number) * 0.1rem;
}
