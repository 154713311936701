// Colors
@import "colors";

// Paths
$path-root: "../../../";
$path-node-modules: $path-root + "node_modules/";
$path-font-awesome: $path-node-modules + "font-awesome/";
$path-bootstrap: $path-node-modules + "bootstrap-sass/";

// Font-awesome
$fa-font-path: $path-font-awesome + "fonts/";
$icon-font-path: $path-bootstrap + "assets/fonts/bootstrap/";

// Fonts
$font-family-open-sans: "Open Sans", sans-serif;
$font-family-special-elite: "Special Elite", cursive;
$font-family-lato: "Lato", sans-serif;
$font-family-oswald: "Oswald", sans-serif;
$font-family-base: $font-family-open-sans;
$font-size-base: 16px;
$line-height-base: 1.625; // 26px
$text-color: $grey-med;

// Font weights
$weight-light: 100;
$weight-normal: 400;
$weight-semi-bold: 600;
$weight-bold: 700;
$weight-black: 900;
$semi-bold: $weight-semi-bold;

// Links
$link-color: $green-dark;
$link-hover-color: $green;
$link-hover-decoration: none;

// Headings
$font-size-h1: 3.0rem;
$font-size-h2: 2.6rem;
$font-size-h3: 2.4rem;
$font-size-h4: 2.0rem;
$font-size-h5: 1.8rem;
$font-size-h6: 1.8rem;

// Borders
$border-radius-base: 0;

// Buttons
$btn-font-weight: bold;
$btn-border-radius-base: 3px;
$btn-padding: 0.5rem 2.5rem;

// Navbar
$navbar-default-color: $grey-med;
$navbar-default-link-hover-color: $black;
$nav-link-hover-bg: $grey-light;

// Footer
$footer-padding: 4rem;

// Featured items
$featured-items-background-opacity: 0.95;

// Breadcrumbs
$breadcrumb-separator: ">";
$breadcrumb-color: $grey-med;
$breadcrumb-active-color: $grey-med;
$breadcrumb-bg: transparent;
$breadcrumb-padding-horizontal: 0;

// Checkboxes
$checkbox-padding: $grid-gutter-width * 0.35;
$checkbox-left-padding: $grid-gutter-width * 1.5;

// Label
$label-default-bg: $grey-light;

// Panels
$panel-inner-border: transparent;

// Tables
$table-cell-padding: 1rem 5rem;

// Dropdowns
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $black;

// Selects
$select-dropdown-triangle-width: 0.75rem;
