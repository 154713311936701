.btn {
  padding: $btn-padding;
  text-transform: uppercase;
}

%normal-button-size {
  font-size: 1.8rem;
}

.btn-green {
  @include button-variant-with-hover($black, $green, $green, $black, $green-med, $green-med);
  @extend %normal-button-size;
}

.btn-black {
  @include button-variant-with-hover($white, $black, $black, $white, $grey-med, $grey-med);
  @extend %normal-button-size;
}

.btn-orange {
  @include button-variant-with-hover($white, $orange, $orange, $white, $orange-med, $orange-med);
  @extend %normal-button-size;
}

.btn-purple {
  @include button-variant-with-hover($black, $purple, $purple, $black, $purple-med, $purple-med);
  @extend %normal-button-size;
}

.btn-grey {
  @include button-variant-with-hover($white, $grey-med, $grey-med, $black, $grey-light, $grey-light);
  @extend %normal-button-size;
  border-radius: 2px;
  font-size: 1.2rem;
  font-weight: $semi-bold;
}

.btn-white {
  @include button-variant-with-hover($black, $white, $white, $white, $black, $black);
  @extend %normal-button-size;
}

.btn-white-alt {
  @include button-variant-with-hover($brown, $white, $grey-light, $white, $black, $black);
  @extend %normal-button-size;
  text-transform: capitalize;
  font-family: $font-family-special-elite;
  font-weight: normal;
}
