$wiggle-distance: 10deg;

.back-to-top {
  position: fixed;
  right: 5vw;
  bottom: 5vh;
  transition: 1s ease opacity;
  opacity: 1;

  &.fade-out {
    transition: 1s ease opacity;
    opacity: 0;
  }

  a {
    display: block;

    &:hover img {
      animation: wiggle 0.5s;
    }

    * {
      pointer-events: none;
    }
  }

  img {
    width: 5rem;
    height: 5rem;
    transform-origin: center;
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  33% {
    transform: rotate($wiggle-distance);
  }

  66% {
    transform: rotate(-$wiggle-distance);
  }

  100% {
    transform: rotate(0deg);
  }
}
