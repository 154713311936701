@mixin button-variant-with-hover($color, $background, $border, $color-hover: $color, $background-hover: darken($background, 10%), $border-hover: $border) {
  color: $color;
  border-color: $border;
  background-color: $background;

  &:focus,
  &.focus {
    color: $color-hover;
    border-color: $border-hover;
    background-color: $background-hover;
  }

  &:hover {
    color: $color-hover;
    border-color: $border-hover;
    background-color: $background-hover;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color-hover;
    border-color: $border-hover;
    background-color: $background-hover;

    &:hover,
    &:focus,
    &.focus {
      color: $color-hover;
      border-color: $border-hover;
      background-color: $background-hover;
    }
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled] {
    &:hover,
    &:focus,
    &.focus {
      border-color: $border;
      background-color: $background;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}
