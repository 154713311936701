@import "../abstracts/variables";

.status {
  padding: 1.0rem 2rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: $weight-bold;
}

.status_required {
  color: $white;
  background-color: $blue-med;
}

.status_alternative {
  color: $grey-med;
  background-color: $grey-light;
}
