.site-header {
  color: $white;
  background-color: $black;

  a:not(.btn) {
    color: inherit;
  }

  .upper {
    padding: 4rem 0;
    text-align: center;
  }

  .lower {
    color: $black;
    background-color: $white;
  }

  .logo {
    max-width: 55rem;
  }

  .user-account-menu {
    margin-top: 3rem;
    text-align: center;

    a + a {
      margin-left: 1.5rem;
      padding-left: 1.5rem;
      border-left: 1px solid $white;
    }
  }
}

@media (min-width: $screen-md) {
  .site-header {
    .upper {
      text-align: left;
    }

    .user-account-menu {
      text-align: right;
    }
  }
}
