.label {
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  font-size: 1.2rem;

  &:empty {
    &[type="submit"] {
      display: block;
    }
  }
}

.label-default {
  height: 100%;
  color: $black;
  border: 0;
}
