@import "../abstracts/variables";
@import "../abstracts/functions";

select,
input {
  padding: 0.5rem $grid-gutter-width/2;
}

.select-wrapper {
  @supports (-moz-appearance: none) or (-webkit-appearance: none) or (appearance: none) {
    position: relative;

    select {
      border: 1px solid $grey-light-extra;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &:before {
      position: absolute;
      top: 50%;
      right: px-to-rem($grid-gutter-width/2) + $select-dropdown-triangle-width/2;
      display: block;
      width: 0;
      height: 0;
      content: "";
      transform: translateY(-50%);
      pointer-events: none;
      border-top: $select-dropdown-triangle-width solid $grey-med;
      border-right: $select-dropdown-triangle-width solid transparent;
      border-left: $select-dropdown-triangle-width solid transparent;
    }
  }
}
