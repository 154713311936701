@import "../abstracts/variables";

.shopping-cart {
  text-transform: none;

  .shopping-cart--item-list {
    padding: 0;
  }

  .shopping-cart--item {
    display: flex;
    list-style: none;
    align-items: center;

    .shopping-cart--item--image-wrapper {
      width: 25%;
      height: auto;
      padding: 1rem;
    }

    .shopping-cart--item--image {
    }

    .shopping-cart--item--description {
      width: 50%;
      padding: 0 1rem;
    }

    .shopping-cart--item--price {
      width: 25%;
      text-align: center;
    }
  }

  .shopping-cart--actions {
    margin-top: $grid-gutter-width/2;
    text-align: right;
  }
}

.shopping-cart_widget {
  width: 100%;
  padding: 2.5rem $grid-gutter-width/2 3.5rem;
  border: 1px solid $green;
}

.shopping-cart_page {
  tbody td {
    &:first-of-type {
      text-align: center;

      > * {
        margin: 0 auto;
      }
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      vertical-align: top;
    }

    &:nth-of-type(2) {
      min-width: 18.5rem;
    }
  }

  tfoot td {
    &:nth-of-type(3) {
      text-align: right;
    }
  }

  .shopping-cart--actions {
    display: flex;
    flex-direction: column;

    .btn {
      margin-top: 1.5rem;
    }
  }
}

// Removing inline styles from smart menus
.whopping-cart-widget-wrapper {
  width: auto !important;
  max-width: 99vw !important;
}

@media (min-width: $screen-sm) {
  .shopping-cart_page .shopping-cart--actions {
    display: block;
  }

  .shopping-cart_widget {
    width: 45rem;
    box-shadow: 0.35rem 0.35rem 0.3rem 0 $grey-dark;
  }
}
