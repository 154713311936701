@import "../variables";

%bookstore-panel {
  border-radius: 0;
  box-shadow: none;

  .panel-heading {
    width: 100%;
    padding: 0;

    .panel-title {
      display: flex;
      width: 100%;

      > a {
        position: relative;
        width: 100%;
        padding: $checkbox-padding $checkbox-padding $checkbox-padding $checkbox-left-padding;
        border: 1px solid $green;
        border-radius: 0;
        background-color: $green-light;

        &:before,
        &:after {
          position: absolute;
          top: 50%;
          left: $checkbox-padding * 2;
          display: block;
          content: "";
          transform: translate(-50%, -50%);
          background-color: $black;
        }

        &:before {
          width: 0.2rem;
          height: 1rem;
          transition: 0.5s opacity ease;
          opacity: 0;
        }

        &:after {
          width: 1rem;
          height: 0.2rem;
        }

        &.collapsed {
          border-color: $grey-med-light;
          background-color: $white;

          &:before {
            transition: 0.5s opacity ease;
            opacity: 1;
          }
        }
      }
    }
  }

  .panel-body {
    margin-top: -1px;
    padding: $grid-gutter-width/2 0 0 $grid-gutter-width*1.5;

    .panel-group:last-of-type {
      margin-bottom: 0;
    }
  }
}
