@import "../abstracts/variables";

.checkbox {
  position: relative;
  display: flex;
  margin: 0 0 $grid-gutter-width/2;
  border: 1px solid $grey-med-light;
  background-color: $white;

  + .checkbox {
    margin-top: 0;
  }

  label {
    width: 100%;
    padding: $checkbox-padding $checkbox-padding $checkbox-padding $checkbox-left-padding;
    font-size: 1.8rem;
    font-weight: $semi-bold;
  }

  input {
    &[type="checkbox"] {
      top: 0;
      width: 2rem;
      height: 100%;
      margin: 0;

      @supports (-webkit-appearance: none) or (-moz-appearance: none) {
        -webkit-appearance: none;
        -moz-appearance: none;

        &:before {
          position: absolute;
          top: 50%;
          left: #{$checkbox-padding - $checkbox-left-padding};
          display: block;
          width: 1.25rem;
          height: 1.25rem;
          content: "";
          transform: translateY(-50%);
          border: 1px solid $grey-med-light;
          background-color: $white;
        }
      }

      &:checked {
        &:before {
          background-color: $grey-med;
        }

        + label {
          background-color: $green-light;
        }
      }
    }

    &[type="submit"] {
      display: none;
      padding: 0 3rem;
      text-transform: uppercase;
      color: $black;
      border: 0;
      background: $green;
      font-weight: $semi-bold;
    }
  }

  &.checked {
    border: 1px solid $green;
    background-color: $green-light;

    input {
      &[type="submit"] {
        display: block;
      }
    }
  }
}
