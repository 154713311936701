@import "../abstracts/variables";

.book {
  position: relative;
  width: 100%;
  padding: 2.5rem 2rem;
  border: 1px solid $grey-med-light;
  border-top-width: 0;

  + .book {
    margin-top: 1.5rem;
    border-top-width: 1px;
  }

  .book--image-wrapper {
    width: 100%;
    height: 15.5rem;
  }

  .book--image {

  }

  // TODO Rename
  h6 {
    margin-top: 0;
  }

  table td:first-of-type {
    padding-right: 2rem;
  }

  .panel {
    margin: 1.5rem 0 0;

    .panel-body {
      padding-bottom: $grid-gutter-width/2;
    }
  }

  .book--status {
    display: block;
    width: 100%;
    margin: 0 0 1.5rem;
  }

  .btn {
    margin-top: 1.5rem;
  }
}

@media (min-width: $screen-sm) {
  .book {
    .book--status {
      position: absolute;
      top: 0;
      right: 0;
      width: 18rem;
    }

    > .row {
      width: calc(100% - 18rem);
    }
  }
}
