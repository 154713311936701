.pointer {
  position: absolute;
  width: 26rem;
  height: 23rem;
  background-image: url("/assets/dist/img/Arrow-green.png");
  background-repeat: no-repeat;
  background-size: contain;

  .pointer-text {
    position: absolute;
    top: 45%;
    left: 48%;
    width: 100%;
    transform: translate(-50%, -50%) rotate(-12.5deg);
    text-align: center;
    color: $black;
    font-size: 2.9rem;
    font-weight: bold;
  }
}
