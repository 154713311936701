@import "../abstracts/variables";
@import "../abstracts/placeholders/panels";

.panel-group {
  margin-bottom: 1.5rem;
}

.panel-courses {
  @extend %bookstore-panel;

  .panel-body {
    border: 0;
  }
}

.panel-text {
  @extend %bookstore-panel;

  .panel-body {
    border: 1px solid $green;
    border-top: 0;
  }
}
