.carousel {
  .carousel-caption {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3rem 3rem 6rem;
    border: 1px solid $green;
    background-color: rgba($black, 0.75);

    .title,
    .description {
      text-align: left;
    }

    .title {
      display: block;
      margin-top: 0;
    }

    .description {
      font-size: 1.9rem;
      font-weight: $semi-bold;
    }
  }

  .carousel-control {
    &.left,
    &.right {
      background-image: none;
    }

    .arrow {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 2rem;
      }
    }
  }

  .carousel-inner img {
    width: 100%;
  }
}

.product-carousel {
  .active,
  .next,
  .prev {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
  }

  .carousel-control {
    width: 4rem;

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }
  }
}

@media (min-width: $screen-sm) {
  .carousel {
    .carousel-caption {
      position: absolute;
      top: 10%;
      right: 50%;
      bottom: auto;
      left: 15%;
      width: 40rem;
      padding-bottom: 3rem;
    }

    .carousel-control .arrow img {
      width: 3rem;
    }
  }
}

@media (min-width: $screen-sm + 100px) {
  .product-carousel {
    .carousel-control.right {
      right: -4rem;
    }

    .carousel-control.left {
      left: -4rem;
    }
  }
}

@media (min-width: $screen-md) {
  .carousel {
    .carousel-caption {
      top: 10%;
      right: auto;
      left: 15%;
      width: 40rem;
    }

    .carousel-control .arrow img {
      width: 4rem;
    }
  }

  .product-carousel {
    .carousel-control {
      &.right {
        right: 0;
      }

      &.left {
        left: 0;
      }
    }
  }
}

@media (min-width: $screen-md + 200px) {
  .product-carousel {
    .carousel-control {
      &.right {
        right: -5rem;
      }

      &.left {
        left: -5rem;
      }
    }
  }
}
