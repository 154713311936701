.product-sidebar--title {
  padding-left: $grid-gutter-width/2;
}

.product-sidebar--menu {
  text-transform: capitalize;

  > li {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid $grey-light-extra;

    &:first-of-type {
      border-top: 1px solid $grey-light-extra;
    }

    > a {
      color: $grey-med;
      font-weight: $weight-semi-bold;
    }

    &.active {
      background-color: $purple;

      > a {
        color: $black;
        background-color: inherit;
      }

      > ul {
        display: block;
      }
    }

    > ul {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0;
      background-color: inherit;
      box-shadow: none;

      > li {
        > a {
          padding: 0.75rem 2rem;
          color: $black;
        }

        &.active {
          background-color: $black;

          > a {
            color: $white;
            background-color: inherit;
          }
        }
      }
    }
  }
}
