.item-card {
  display: block;
  width: 100%;
  margin-bottom: 3rem;
  padding: 1rem;
  text-align: center;
  color: $black;
  background-color: $white;

  &:hover,
  &:focus {
    color: inherit;
  }

  .item-card--image {
    width: 100%;
    height: auto;
  }

  .item-card--title {
    display: block;
    padding: 1.5rem;
    text-transform: capitalize;
    font-size: 2.2rem;
    font-weight: $semi-bold;
  }
}
