.site-footer {
  margin-top: 15rem;
  color: $white;

  .our-story + & {
    margin-top: 0;
  }

  .site-footer--room-num {
    padding: 3rem 0;
    text-transform: capitalize;
    color: $white;
    background-color: $orange;
    font-size: 3.6rem;
  }

  .site-footer--map {
    position: relative;

    .map {
      width: 100%;
    }

    .pointer {
      top: 6%;
      right: 49%;
      transform: rotate(65deg);
      transform-origin: right center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .site-footer--contact {
    background-image: linear-gradient($grey-dark var(--footer-location-height), $purple 1px);

    .location {
      background-color: $grey-dark;
    }

    .contact-hours {
      background-color: $purple;

      .hours {
        text-transform: capitalize;

        tbody tr td:first-of-type {
          padding-right: 1rem;
        }
      }
    }
  }

  .site-footer--menu {
    padding: $footer-padding 0;
    background-color: $black;

    .copyright {
      font-size: 1.2rem;
    }

    .nav > li > a {
      color: $green;

      &:hover,
      &:focus {
        background-color: $grey-med;
      }
    }
  }

  .social-media {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    color: $white;

    a {
      color: inherit;
    }

    li + li {
      margin-left: 1rem;
    }
  }

  a {
    color: inherit;

    &[href*="mailto:"] {
      border-bottom: 1px solid $white;
    }
  }

  .padd {
    padding: $footer-padding 0;
  }
}

@media (min-width: $screen-sm) {
  .site-footer {
    .site-footer--map .pointer {
      top: 10%;
    }
  }
}

@media (min-width: $screen-md) {
  .site-footer {
    .site-footer--contact {
      background-image: linear-gradient(90deg, $grey-dark 25vw, $purple 1px);
    }

    .site-footer--map .pointer {
      top: -2%;
    }
  }
}

@media (min-width: $screen-lg) {
  .site-footer {
    .site-footer--map .pointer {
      top: 0;
    }
  }
}
