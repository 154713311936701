$black: #000;
$grey-dark: #333;
$grey-med: #4d4d4d;
$grey-med-light: #ccc;
$grey-light: #e6e6e6;
$grey-light-extra: #efefef;
$white: #fff;

$green-light: #edf2c0;
$green: #c4d600; // Logo
$green-med: #8b9f00; // 18px and over
$green-dark: #6b7a00; // 17px and under
$green-bright: #98c21f;

$blue: #26b4b9;
$blue-med: #00a5ab; // 18px and over
$blue-dark: #037f84; // 17px and under

$red: #bb1f1c;

$orange: #ee7520;
$orange-med: #e46a14; // 18px and over
$orange-dark: #c35507; // 17px and under

$brown: #7f745b; // 18px and over
$brown-dark: #5c5543; // 17px and under
$brown-light: #f0eeea;

$purple: #8b98c4;
$purple-med: #7582b0; // 18px and over
$purple-dark: #686e87; // 17px and under
