.our-story {
  position: relative;
  min-height: 100rem;
  padding-top: 7.5rem;
  background-color: $brown-light;
  background-image: url("../img/paper-background.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;

  .background {
    position: absolute;
    bottom: 0;
    left: 50%;
    padding: $grid-gutter-width/2;
    transform: translateX(-50%);
  }

  .our-story--title {
    text-align: center;
  }

  .our-story--body {
    line-height: 2;

    .btn {
      margin-top: 2.5rem;
    }
  }

  .pointer {
    z-index: 10;
    bottom: -8%;
    left: 0;
    transform: rotate(-35deg);
    transform-origin: right center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .our-story--images {
    padding-top: 2rem;

    img {
      &:nth-of-type(1) {
        transform: rotate(-7deg);
      }

      &:nth-of-type(2) {
        float: right;
        transform: translateY(-4rem) rotate(2deg);
      }
    }
  }
}

@media (min-width: $screen-sm) {
  .our-story .our-story--images {
    padding-top: 10rem;
  }
}

@media (min-width: $screen-md) {
  .our-story {
    min-height: 80rem;

    .pointer {
      left: calc(50% - 450px);
    }

    .our-story--images {
      padding-top: 2rem;
    }
  }
}

@media (min-width: $screen-lg) {
  .our-story .pointer {
    left: calc(50% - 500px);
  }
}
