.navbar-toggle {
  .icon-bar {
    transition: 0.5s ease;
    opacity: 1;
    background-color: $white;

    &:nth-last-of-type(3) {
      transform: translate(0, 6px) rotate(45deg);
    }

    &:nth-last-of-type(2) {
      transform: rotate(0deg);
      opacity: 0;
    }

    &:nth-last-of-type(1) {
      transform: translate(0, -6px) rotate(-45deg);
    }
  }

  &.collapsed {
    .icon-bar {
      transition: 0.5s ease;

      &:nth-last-of-type(3) {
        transform: rotate(0deg);
      }

      &:nth-last-of-type(2) {
        transform: rotate(0deg);
        opacity: 1;
      }

      &:nth-last-of-type(1) {
        transform: rotate(0deg);
      }
    }
  }
}

.navbar-menu {
  .active,
  .open {
    > a {
      &,
      &:hover,
      &:focus {
        background-color: $green;
      }
    }

    .dropdown-menu:not(.no-active-bg) {
      background-color: $green;
    }
  }
}

.main-menu {
  text-transform: capitalize;
  font-size: 1.8rem;
  font-weight: $semi-bold;
}

